import { CRM_URL, db } from "./firebase";
import { doc, getDoc, } from "firebase/firestore";
import moment from "moment";
import { setOrdersUpdating } from "store/slices/userSlice";
import { store } from "store";
import { setCustomersUpdating } from "store/slices/userSlice";
import { setPaymentsUpdating } from "store/slices/userSlice";
import { setShipmentsUpdating } from "store/slices/userSlice";
import i18n from "i18next";
import api from "api";

export const fileSizeIsValid = (fileSize, maxSize) => {
  const currentSize = fileSize / 1024 / 1024;

  return currentSize <= maxSize;
}

export const chunk = (arr, size) => {
  const result = [];

  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    result.push(arr.slice((i * size), (i * size) + size));
  }

  return result;
}

export const getTierLevel = async (expertId) => {
  let limit = 0

  const { data: ordersArr } = await api.get('ordersByExpertID', { params: { expertId } })

  if (!ordersArr?.length) return 0

  const activeUsers = ordersArr
    .filter(i => !['payment_needed', 'refunded', 'canceled'].includes(i.status))
    .filter(j => {
      const created = moment(j.created)
      const diff = moment(Date.now()).diff(created, 'days')

      if (diff <= 60) return j
    })
    .map(k => k.user_id)

  const paidUsersCount = [...new Set(activeUsers)].length

  const pricesRef = doc(db, 'Meta', 'PartnersPrices')
  const pricesSnap = await getDoc(pricesRef)

  if (pricesSnap.exists()) {
    const prices = pricesSnap.data()

    if (paidUsersCount < prices.limit5) {
      limit = 5
    } else if (paidUsersCount >= prices.limit5 && paidUsersCount < prices.limit10) {
      limit = 10
    } else if (paidUsersCount >= prices.limit10 && paidUsersCount < prices.limit15) {
      limit = 15
    } else if (paidUsersCount >= prices.limit15) {
      limit = 20
    }
  }

  return limit
}

export const getUserActionStatus = async (userData, order, checkInactive = false, userPackage) => {
  let lStatus = userData?.lStatus || 'active'
  let actionStatus = userData?.lStatus || 'registered'
  let lastPackage = ''
  let isRefund = false

  if (userData?.weeklyQuestionnaireDone === 0) {
    lStatus = 'action_needed'
  }

  if (
    order.status === 'payment_needed' ||
    order.status === 'paid_stripe' ||
    order.status === 'paid_shopify'
  ) {
    lStatus = 'action_needed'
  } else if (order.status === 'delivered' || order.status === 'delivery_in_process') {
    lStatus = 'active'
  }

  if (order.status === 'payment_needed' && order.submitted === 1) {
    actionStatus = 'payment_needed'
  } else if (order.status === 'paid_stripe' || order.status === 'paid_shopify') {
    if (['registered', 'waiting_list', 'questionnaire1'].includes(userData?.status) && !userData?.skipQuestionnaire) {
      actionStatus = 'skip_initial'
    } else if (
      ['questionnaire1', 'waiting_diagnose', 'waiting_payment', 'waiting_delivery', 'active', 'lost_customer'].includes(userData?.status) &&
      userData?.weeklyQuestionnaireDone === 0
    ) {
      actionStatus = 'skip_weekly'
    } else if (order?.assessmentId && order.submitted === 0) {
      actionStatus = 'send_assessment'
    } else if (order?.type === 'rb') {
      lStatus = 'assessment_needed'
      actionStatus = 'update_assessment'
    }
  } else if (order.status === 'questionnaire_needed') {
    if (['waiting_diagnose', 'waiting_payment', 'waiting_delivery', 'active', 'lost_customer'].includes(userData?.status)) {
      if (userData?.weeklyQuestionnaireDone === 1) {
        lStatus = 'assessment_needed'
        actionStatus = 'give_assessment'
      } else {
        lStatus = 'questionnaire_needed'
        actionStatus = 'skip_weekly'
      }
    } else if (['registered', 'waiting_list', 'questionnaire1'].includes(userData?.status)) {
      if (userData?.skipQuestionnaire === 1) {
        lStatus = 'assessment_needed'
        actionStatus = 'give_assessment'
      } else {
        lStatus = 'questionnaire_needed'
        actionStatus = 'skip_initial'
      }
    } else {
      lStatus = 'action_needed'
    }
  } else if (order.submitted === 0) {
    actionStatus = 'send_assessment'
  } else {
    actionStatus = 'give_assessment'
  }

  if (order.status === 'paid_stripe' || order.status === 'paid_shopify' || order.status === 'delivery_needed') {
    isRefund = true
  } else if (order.status === 'delivery_in_process' || order.status === 'delivered') {
    const diff = moment().diff(moment(order?.paymentDate), 'days')
    if (diff < 14) {
      isRefund = true
    }
  }

  if (checkInactive) {
    const created = moment(userPackage?.created)
    const diff = moment(Date.now()).diff(created, 'days')

    lastPackage = created

    if (diff > 60 && order.status !== 'payment_needed' && order.status !== 'paid_stripe' && order.status !== 'paid_shopify') {
      lStatus = 'inactive'
      actionStatus = 'inactive'
    }
  }

  return {
    lStatus,
    actionStatus,
    lastPackage,
    isRefund,
  }
}

export const getOrders = async (userID) => {
  store.dispatch(setOrdersUpdating(true))

  let usersData = []

  const { data: usersByExpertID } = await api.get('usersByExpertID', { params: { expertId: userID } })

  if (usersByExpertID?.length) {
    usersByExpertID.forEach(i => {
      usersData.push({
        ...i,
        name:
          ((i?.firstName && i?.lastName) && `${i?.firstName} ${i?.lastName}`) ||
          i?.name || '-',
      });
    })
  }

  const { data: ordersData } = await api.get('ordersByExpertID', { params: { expertId: userID } })

  if (ordersData?.length) {
    for (let i = 0; i < ordersData.length; i++) {
      const order = ordersData[i];

      if (order?.user_id) {
        const userData = usersData.find(i => i.id === order.user_id)

        const {
          actionStatus,
          isRefund,
        } = await getUserActionStatus(userData, order)

        ordersData[i] = {
          ...order,
          userId: i.user_id,
          name:
            ((userData?.firstName && userData?.lastName) && `${userData?.firstName} ${userData?.lastName}`) ||
            userData?.name || '-',
          email: userData?.email || '-',
          userStatus: userData?.status,
          weeklyQuestionnaireDone: userData?.weeklyQuestionnaireDone,
          actionStatus,
          isRefund
        }
      }
    }

    const sortedOrders = ordersData.sort((a, b) => new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1)

    localStorage.setItem(`orders_${userID}`, JSON.stringify(sortedOrders))
    window.dispatchEvent(new Event('storage'))
    store.dispatch(setOrdersUpdating(false))
    return sortedOrders
  }
  return []
}

export const updateStorageOrders = async (expertId, userData, order, orderId) => {
  const storageOrders = JSON.parse(localStorage.getItem(`orders_${expertId}`)) || []
  const storageUsers = JSON.parse(localStorage.getItem(`users_${expertId}`)) || []
  const storagePayments = JSON.parse(localStorage.getItem(`payments_${expertId}`)) || []
  const storageDiscounts = JSON.parse(localStorage.getItem(`discounts_${expertId}`))

  let newOrder = orderId
    ? {
      ...storageOrders.find(i => i.id === orderId),
      ...order
    }
    : order

  const {
    lStatus,
    actionStatus,
    lastPackage,
    isRefund,
  } = await getUserActionStatus(userData, newOrder)

  newOrder = {
    ...newOrder,
    created: orderId ? newOrder.created : new Date(),
    name: ((userData?.firstName && userData?.lastName) && `${userData?.firstName} ${userData?.lastName}`) ||
      userData?.name || '-',
    actionStatus,
    isRefund,
  }

  const newStorageOrders = JSON.stringify(
    orderId
      ? storageOrders.map(i => {
        if (i.id === orderId) {
          return { ...i, ...newOrder }
        }
        return i
      })
      : [newOrder, ...storageOrders]
  )

  localStorage.setItem(`orders_${expertId}`, newStorageOrders)

  const newStorageUsers = JSON.stringify(
    storageUsers.map(i => {
      if (i.id === userData.id) {
        return {
          ...i,
          lStatus,
          actionStatus,
          lastPackage,
          isRefund,
        }
      }
      return i
    })
  )

  localStorage.setItem(`users_${expertId}`, newStorageUsers)

  if (newOrder.status === 'payment_needed' && !newOrder.paid) {
    if (storageDiscounts) {
      const {
        discount,
        rbDiscount,
        qcADiscount,
        qcBDiscount,
        userCurrency
      } = storageDiscounts

      const pricesRef = doc(db, "Meta", "PartnersPrices")
      const pricesSnap = await getDoc(pricesRef)
      if (pricesSnap.exists()) {
        const prices = pricesSnap.data()

        const newPayment = [newOrder].map(i => {
          let consumerTotal = 0
          let priceId = ''

          if (i?.type.includes('qc')) {
            consumerTotal = prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`]
            priceId = prices[`${i.type}PricePartner${qcADiscount}ID${userCurrency}${!!userData?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          } else if (i?.type === 'ds') {
            consumerTotal = prices[`${i.type}PricePartner${qcADiscount}${userCurrency}`]
            priceId = prices[`${i.type}PricePartner${qcADiscount}ID${userCurrency}${!!userData?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          } else if (i?.type === 'rb') {
            consumerTotal = prices[`rbPricePartner${rbDiscount}${userCurrency}`]
            priceId = prices[`rbPricePartner${rbDiscount}ID${userCurrency}${!!userData?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          } else if (i?.type === 'ip') {
            consumerTotal = i?.total
            priceId = prices[`ipShippingPricePartner0ID${userCurrency}${!!userData?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`] +
              i?.product?.products?.reduce((prev, curr) =>
                prev + ',' + new Array(curr?.quantity).fill(prices[`i${curr?.ref}PricePartner0ID${userCurrency}${!!userData?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]).join(',')
                , '')
          }

          return {
            ...i,
            consumerTotal,
            priceId,
            total: consumerTotal - (consumerTotal * ((discount) / 100)),
          }
        })

        localStorage.setItem(`payments_${expertId}`, JSON.stringify([
          ...newPayment,
          ...storagePayments
        ]))
      }
    }
  }

  window.dispatchEvent(new Event('storage'))
}

export const getUsers = async (userID) => {
  store.dispatch(setCustomersUpdating(true))

  let usersData = []

  const { data: usersByExpertID } = await api.get('usersByExpertID', { params: { expertId: userID } })

  if (usersByExpertID?.length) {
    usersByExpertID.forEach(i => {
      usersData.push({
        ...i,
        name:
          ((i?.firstName && i?.lastName) && `${i?.firstName} ${i?.lastName}`) ||
          i?.name || '-',
      });
    })
  }

  const { data: ordersData } = await api.get('ordersByExpertID', { params: { expertId: userID } })
  const { data: expertPackages } = await api.get('getLastPackagesByExpertID', { params: { expertId: userID } })

  if (usersData.length) {
    for (let i = 0; i < usersData.length; i++) {
      const userData = usersData[i];

      const userOrders = ordersData
        .filter(j => j.user_id === userData.id)
        .filter(k => k.status !== 'canceled')
        .sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? -1 : 1)

      if (userOrders.length) {
        let lastOrder = ''
        let lastOrderStatus = ''
        let lastOrderId = ''
        let lastOrderTreatmentId = ''
        let lastOrderPaymentId = ''

        const order = userOrders[userOrders.length - 1];
        const userPackage = expertPackages.find(i => i.user_id === userData.id)

        let {
          lStatus,
          actionStatus,
          lastPackage,
          isRefund,
        } = await getUserActionStatus(userData, order, true, userPackage)

        lastOrder = order.created
        lastOrderStatus = order.status
        lastOrderId = order.id
        lastOrderTreatmentId = order?.treatmentId
        lastOrderPaymentId = order?.paymentId

        usersData[i].lStatus = lStatus
        usersData[i].actionStatus = actionStatus
        usersData[i].lastPackage = lastPackage
        usersData[i].lastOrder = lastOrder
        usersData[i].lastOrderStatus = lastOrderStatus
        usersData[i].lastOrderId = lastOrderId
        usersData[i].lastOrderTreatmentId = lastOrderTreatmentId
        usersData[i].lastOrderPaymentId = lastOrderPaymentId
        usersData[i].isRefund = isRefund

        if (lStatus !== userData?.lStatus) {
          await api.post('updateUser', { id: userData.id, lStatus })
        }
      } else {
        usersData[i].lStatus = 'registered'
        usersData[i].actionStatus = 'give_assessment'
      }
    }
  }

  const sortedUsers = usersData.sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? 1 : -1)

  localStorage.setItem(`users_${userID}`, JSON.stringify(sortedUsers))
  window.dispatchEvent(new Event('storage'))
  store.dispatch(setCustomersUpdating(false))
  return sortedUsers
}

export const getPayments = async (userID) => {
  store.dispatch(setPaymentsUpdating(true))

  const { data: ordersArr } = await api.get('ordersByExpertID', { params: { expertId: userID } })

  let paymentNeededOrders = ordersArr?.filter(i => i.status === 'payment_needed' && !i.paid) || []

  if (paymentNeededOrders?.length === 0) {
    localStorage.setItem(`payments_${userID}`, JSON.stringify([]))
    store.dispatch(setPaymentsUpdating(false))
    return null
  } else {
    const { data: userByID } = await api.get('userByID', { params: { userId: userID } })

    const tierDiscount = userByID?.tierDiscount
    const rbDiscount = (userByID?.rbDiscount === undefined || userByID?.rbDiscount === null) ? 5 : userByID?.rbDiscount
    const qcADiscount = (userByID?.qcADiscount === undefined || userByID?.qcADiscount === null) ? 5 : userByID?.qcADiscount
    const qcBDiscount = (userByID?.qcBDiscount === undefined || userByID?.qcBDiscount === null) ? 5 : userByID?.qcBDiscount

    const tierLevel = !tierDiscount ? await getTierLevel(userID) : null

    // let userCurrency = ''
    // if (
    //   ['GB', 'UK', 'United Kingdom'].includes(userByID?.shippingAddress?.country) ||
    //   ['GB', 'UK', 'United Kingdom'].includes(userByID?.country)
    // ) {
    //   userCurrency = 'UK'
    // } else {
    //   userCurrency = 'HU'
    // }

    localStorage.setItem(`discounts_${userID}`, JSON.stringify({
      discount: tierDiscount || tierLevel,
      rbDiscount,
      qcADiscount,
      qcBDiscount,
      // userCurrency
    }))

    // get price
    const pricesRef = doc(db, "Meta", "PartnersPrices")
    const pricesSnap = await getDoc(pricesRef)
    if (pricesSnap.exists()) {
      const prices = pricesSnap.data()

      paymentNeededOrders = paymentNeededOrders.map(i => {
        let consumerTotalUK = 0
        let consumerTotalHU = 0
        let priceIdUK = ''
        let priceIdHU = ''

        if (i?.type.includes('qc')) {
          consumerTotalUK = prices[`${i.type}PricePartner${qcADiscount}UK`]
          consumerTotalHU = prices[`${i.type}PricePartner${qcADiscount}HU`]
          priceIdUK = prices[`${i.type}PricePartner${qcADiscount}IDUK${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          priceIdHU = prices[`${i.type}PricePartner${qcADiscount}IDHU${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
        } else if (i?.type === 'ds') {
          consumerTotalUK = prices[`${i.type}PricePartner${qcADiscount}UK`]
          consumerTotalHU = prices[`${i.type}PricePartner${qcADiscount}HU`]
          priceIdUK = prices[`${i.type}PricePartner${qcADiscount}IDUK${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          priceIdHU = prices[`${i.type}PricePartner${qcADiscount}IDHU${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
        } else if (i?.type === 'rb') {
          consumerTotalUK = prices[`rbPricePartner${rbDiscount}UK`]
          consumerTotalHU = prices[`rbPricePartner${rbDiscount}HU`]
          priceIdUK = prices[`rbPricePartner${rbDiscount}IDUK${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
          priceIdHU = prices[`rbPricePartner${rbDiscount}IDHU${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]
        } else if (i?.type === 'ip') {
          consumerTotalUK = i?.total
          consumerTotalHU = i?.total
          priceIdUK = i?.product?.products?.reduce((prev, curr, index) =>
            prev + `${index > 0 ? ',' : ''}` + new Array(curr?.quantity).fill(prices[`i${curr?.ref}PricePartner0IDUK${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]).join(',')
            , '')
          priceIdHU = i?.product?.products?.reduce((prev, curr, index) =>
            prev + `${index > 0 ? ',' : ''}` + new Array(curr?.quantity).fill(prices[`i${curr?.ref}PricePartner0IDHU${!!i?.isTestUser || process.env.REACT_APP_ENV === 'development' ? '_test' : ''}`]).join(',')
            , '')
        }

        return {
          ...i,
          consumerTotalUK,
          consumerTotalHU,
          priceIdUK,
          priceIdHU,
          totalUK: consumerTotalUK - (consumerTotalUK * ((tierDiscount || tierLevel) / 100)),
          totalHU: consumerTotalHU - (consumerTotalHU * ((tierDiscount || tierLevel) / 100)),
        }
      })
    }

    const payments = paymentNeededOrders.sort((a, b) => new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1)
    localStorage.setItem(`payments_${userID}`, JSON.stringify(payments))
    window.dispatchEvent(new Event('storage'))
    store.dispatch(setPaymentsUpdating(false))
    return {
      payments,
      discount: tierDiscount || tierLevel,
      rbDiscount,
      qcADiscount,
      qcBDiscount,
      // userCurrency,
    }
  }
}

export const getShipments = async (userID) => {
  store.dispatch(setShipmentsUpdating(true))

  const res = await api.get('packagesByExpertID', { params: { expertId: userID } })

  if (res?.data?.length) {
    const shipmentArr = res.data
      .filter(i => i?.deleted !== true)
      .sort((a, b) => new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1)

    store.dispatch(setShipmentsUpdating(false))
    return shipmentArr
  }
}

export function numberWithCommas(x) {
  return !!x ? x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
}

export const getMaxRange = (number) => {
  return Array.from({ length: (number).toString().length })
    .reduce((prev, curr) => prev + '0', '1')
}

export const requestPayment = async (userId, userEmail, sentRequestIds, setSentRequestIds, toast) => {
  if (sentRequestIds.includes(userId)) return

  if (confirm(`${i18n.t('portal_customers_table_menu_9')} <${userEmail}>`)) {
    setSentRequestIds(prev => [...prev, userId])

    const expert = await api.get('userByID', { params: { userId: store.getState().user.id } })

    api.post('sendMailgun', {
      user_id: userId,
      to: userEmail,
      subject: 'Request payment',
      template: 'request payment',
      params: {
        button_link: encodeURI(`${process.env.REACT_APP_API_URL}s/emailpayments?email=${userEmail}&country=${expert.data?.country || expert.data?.shippingAddress?.country}`),
        button_name: 'Pay',
        expert_name: `${expert.data?.firstName} ${expert.data?.lastName}`
      }
    })
      .then(() => {
        toast({
          title: i18n.t('portal_customers_table_menu_8'),
          position: 'top-right',
          isClosable: true,
          status: 'success'
        })
      }).catch(() => {
        toast({
          title: i18n.t('portal_customers_table_menu_4'),
          position: 'top-right',
          isClosable: true,
          status: 'error'
        })
      })
  }
}