import React, { useState, useEffect, useCallback } from "react";
import { Flex, Text, Select, Spinner, Box } from "@chakra-ui/react";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import UserAnswers from "components/UserAnswers/UserAnswers";
import { useSelector } from "react-redux";
import { BackArrow } from "components/Icons/Icons";
import { PersonAdd } from "components/Icons/Icons";
import AssessmentForm from "./AssessmentForm/AssessmentForm";
import QCForm from "./QCForm";
import { useTranslation } from "react-i18next";
import useLocalStorage from "hooks/useLocalStorage";
import IPForm from "./IPForm";
import { Select as SearchSelect } from "chakra-react-select";
import RequestQuestionnaireBtn from "components/RequestQuestionnaireBtn";
import api from "api";

const useGetPrevOrders = (userId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prevOrders, setPrevOrders] = useState([]);
  const [latestOrder, setLatestOrder] = useState({});

  const getCreatedOrders = async () => {
    if (!userId?.value) return;
    setIsLoading(true);
    setIsError(false);

    try {
      const prevOrders = [];
      const collectionName = collection(db, "AssessmentForms");
      const queryName = query(
        collectionName,
        where("userId", "==", userId.value),
        orderBy("time", "desc")
      );
      const orders = await getDocs(queryName);
      orders.forEach((doc) => prevOrders.push(doc.data()));
      setPrevOrders(prevOrders);
      if (prevOrders.length) {
        setLatestOrder(
          prevOrders.reduce((latest, current) => {
            return current.time.seconds > latest.time.seconds
              ? current
              : latest;
          })
        );
      } else {
        setLatestOrder({});
      }
      setIsError(false);
    } catch (err) {
      console.log(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCreatedOrders();

    return () => {
      setIsLoading(false);
    };
  }, [userId]);

  return {
    prevOrders,
    setPrevOrders,
    latestOrder,
    isPprevOrdersError: isError,
    isPrevOrdersLoading: isLoading,
  };
};

const OrdersNew = () => {
  const { t } = useTranslation();
  const router = useHistory();
  const { userId, orderId } = useParams();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [order, setOrder] = useState({});
  const [users, setUsers] = useState([]);
  const [prices, setPrices] = useState({});
  const [currency, setCurrency] = useState("");
  const [userAvailability, setUserAvailability] = useState({});
  const [userDiscounts, setUserDiscounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [selectedUser, setSelectedUser] = useLocalStorage("selectedUser", "");
  const [type, setType] = useLocalStorage("orderType", "");
  const { latestOrder } = useGetPrevOrders(selectedUser);

  const fetchUsersByExpertId = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.get("usersByExpertID", {
        params: { expertId: user.id },
      });

      setUsers(data);
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const { data: userByID } = await api.get("userByID", {
        params: { userId },
      });

      if (userByID) {
        const userObj = {
          value: userByID.id,
          label:
            (userByID?.firstName &&
              userByID?.lastName &&
              `${userByID?.firstName} ${userByID?.lastName}`) ||
            userByID?.name ||
            "-",
        };
        setUsers([userByID]);
        setSelectedUser(userObj);
      }

      if (orderId) {
        const { data: orderByID } = await api.get("orderByID", {
          params: { orderId },
        });
        setOrder(orderByID);
        setType(orderByID?.type);
      }
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getPrices = async () => {
    const pricesRef = doc(db, "Meta", "PartnersPrices");
    const pricesDoc = await getDoc(pricesRef);
    if (pricesDoc.exists()) {
      const pricesData = pricesDoc.data();
      setPrices(pricesData);
    }
  };

  const getUser = async () => {
    const { data } = await api.get("userByID", { params: { userId: user.id } });

    setUserAvailability({
      rbAvailability:
        data?.rbAvailability === undefined || data?.rbAvailability === null
          ? true
          : Boolean(data?.rbAvailability),
      qcAvailability:
        data?.qcAvailability === undefined || data?.qcAvailability === null
          ? true
          : Boolean(data?.qcAvailability),
      seAvailability:
        data?.seAvailability === undefined || data?.seAvailability === null
          ? true
          : Boolean(data?.seAvailability),
      ipAvailability:
        data?.ipAvailability === undefined || data?.ipAvailability === null
          ? false
          : Boolean(data?.ipAvailability),
    });
    setUserDiscounts({
      rbDiscount:
        data?.rbDiscount === undefined || data?.rbDiscount === null
          ? 5
          : data?.rbDiscount,
      qcADiscount:
        data?.qcADiscount === undefined || data?.qcADiscount === null
          ? 5
          : data?.qcADiscount,
      qcBDiscount:
        data?.qcBDiscount === undefined || data?.qcBDiscount === null
          ? 5
          : data?.qcBDiscount,
    });
  };

  const getUserByID = async () => {
    const { data } = await api.get("userByID", {
      params: { userId: selectedUser.value },
    });

    if (
      ["GB", "UK", "United Kingdom"].includes(data?.shippingAddress?.country) ||
      ["GB", "UK", "United Kingdom"].includes(data?.country)
    ) {
      setCurrency("UK");
    } else {
      setCurrency("HU");
    }
  };

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  useEffect(() => {
    getPrices();
    if (userId) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (user.id) {
      getUser();
      if (!orderId && !userId) {
        fetchUsersByExpertId();
      }
    }
  }, [user.id]);

  useEffect(() => {
    if (!selectedUser?.value) return;
    getUserByID();
  }, [selectedUser.value]);

  return (
    <Flex flexDirection={"column"}>
      <div onClick={goBack} className="back-link">
        <BackArrow /> {t("portal_orders_new_back_button")}
      </div>
      <Flex
        alignItems={{ lg: "center", sm: "flex-start" }}
        justifyContent="space-between"
        flexDirection={{ lg: "row", sm: "column" }}
        gap={{ lg: 0, sm: "20px" }}
      >
        <div>
          <h2 className="title">{t("portal_orders_new_top_title")}</h2>
          <p className="descr">{t("portal_orders_new_top_description")}</p>
        </div>
        {selectedUser?.value && (
          <RequestQuestionnaireBtn
            userData={users?.find((i) => i?.id === selectedUser?.value) || {}}
            setMailgunStats={() => {}}
          />
        )}
      </Flex>

      <hr className="hr" />

      {isLoading && (
        <Flex mx={"auto"}>
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error" style={{ margin: "0 auto" }}>
          {t("portal_error_message")}
        </p>
      )}
      {!isLoading && !isError && (
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ lg: "row", sm: "column" }}
          gap={{ lg: 0, sm: "20px" }}
        >
          <Flex alignItems={"flex-end"}>
            <div>
              <Text fontWeight={600} fontSize={15} mb={"7px"}>
                {t("portal_orders_new_select_customer_title")}
              </Text>
              <SearchSelect
                value={selectedUser}
                onChange={(value) => setSelectedUser(value)}
                disabled={orderId || userId}
                options={users?.map((user) => ({
                  value: user.id,
                  label:
                    (user?.firstName &&
                      user?.lastName &&
                      `${user?.firstName} ${user?.lastName}`) ||
                    user?.name ||
                    "-",
                }))}
                placeholder={t("portal_orders_new_select_customer_title")}
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    width: { lg: "440px", sm: "240px" },
                    height: "44px",
                    borderColor: "#3D2541",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    bg: "transparent",
                    px: 2,
                    cursor: "inherit",
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                }}
              />
            </div>

            <div
              className="assessment-add"
              onClick={() => router.push("/admin/customers/new?order=true")}
            >
              <PersonAdd />
            </div>
          </Flex>

          <div>
            <Text fontWeight={600} fontSize={15} mb={"7px"}>
              {t("portal_orders_new_select_product_title")}
            </Text>
            <Select
              placeholder={t("portal_orders_new_select_product_title")}
              w={{ lg: "440px", sm: "100%" }}
              h="44px"
              borderColor={"#3D2541"}
              value={type}
              onChange={(ev) => setType(ev.target.value)}
              disabled={orderId && order?.type}
            >
              <option value={"rb"}>
                {t("portal_orders_new_products_choise_1")}
              </option>
              <option value={"ds"}>
                {t("portal_orders_new_products_choise_2")}
              </option>
              <option value={"se"}>
                {t("portal_orders_new_products_choise_5", "Serum")}
              </option>
              <option value={"ip"}>
                {t("portal_orders_new_products_choise_3")}
              </option>
            </Select>
          </div>
        </Flex>
      )}

      <hr className="hr" />

      {type === "rb" ? (
        userAvailability?.rbAvailability ? (
          <Flex
            justifyContent={"space-between"}
            gap={"40px"}
            flexDirection={{ lg: "row", sm: "column-reverse" }}
          >
            <Box w={{ lg: "555px", sm: "100%" }}>
              <h2 className="title">{t("portal_orders_new_af_title")}</h2>
              <p className="descr descr-margin">
                {t("portal_orders_new_af_description")}
              </p>
              <AssessmentForm
                latestOrder={latestOrder}
                userData={users.find((i) => i.id === selectedUser.value)}
                orderId={orderId}
                prices={prices}
                discount={userDiscounts?.rbDiscount}
                currency={currency}
              />
            </Box>
            <Box w={{ lg: "440px", sm: "100%" }}>
              <h2 className="title">{t("portal_orders_new_answers_title")}</h2>
              <p className="descr descr-margin">
                {t("portal_orders_new_answers_description")}
              </p>
              {selectedUser.value ? (
                <UserAnswers userId={selectedUser.value} />
              ) : null}
            </Box>
          </Flex>
        ) : (
          <Text textAlign={"center"}>{t("portal_not_available")}</Text>
        )
      ) : null}
      {type === "qc" || type === "ds" || type === "se" ? (
        userAvailability?.qcAvailability ? (
          <QCForm
            orderType={type}
            userData={users.find((i) => i.id === selectedUser.value)}
            orderId={orderId}
            prices={prices}
            ADiscount={userDiscounts?.qcADiscount}
            BDiscount={userDiscounts?.qcBDiscount}
            currency={currency}
          />
        ) : (
          <Text textAlign={"center"}>{t("portal_not_available")}</Text>
        )
      ) : null}
      {type === "ip" ? (
        userAvailability?.ipAvailability ? (
          <IPForm
            userData={users.find((i) => i.id === selectedUser.value)}
            orderId={orderId}
            prices={prices}
            currency={currency}
          />
        ) : (
          <Text textAlign={"center"}>{t("portal_not_available")}</Text>
        )
      ) : null}
    </Flex>
  );
};

export default OrdersNew;
